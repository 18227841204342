import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { BASE_URL } from "../config";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import axios from "axios";
import games from "../../Images/games.jpg";
import "./DesktopNewsTimer.scss";

const DesktopNewsTimer = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCustomOption, setSelectedCustomOption] = useState("World");

  const [countdown, setCountdown] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const [scrollIndex, setScrollIndex] = useState(0);

  const [isCountdownActive, setIsCountdownActive] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  
  useEffect(() => {
    if (isCountdownActive && data) {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const newIndex = prevIndex + 1;
          if (newIndex >= data.length) {
            clearInterval(timer);
            setIsCountdownActive(false);
           
          }
          return newIndex;
        });
      }, countdown * 1000);
      return () => clearInterval(timer);
    }
  }, [isCountdownActive, countdown, data]);
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setButtonDisabled(true);
    setIsCountdownActive(true);
    switch (option) {
      case 1:
        setCountdown(60);
        break;
      case 3:
        setCountdown(180);
        break;
      case 10:
        setCountdown(600);
        break;
      default:
        setCountdown(0);
    }
  };

  const handleStop = () => {
    setCountdown(0);
    setButtonDisabled(false);
    setIsCountdownActive(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const option = selectedCustomOption || "world";
        let newsSources = [];
        if (selectedCustomOption.toUpperCase() === "INDIA") {
          newsSources = ["indianews", "ndtvnews", "zeenews"];
        } else if (option.toUpperCase() === "AMERICA") {
          newsSources = ["huffpost", "cbsnews", "cnnnews"];
        } else if (option.toUpperCase() === "UK") {
          newsSources = ["bbc"];
        } else if (option.toUpperCase() === "CUBA") {
          newsSources = ["havana", "santiago", "pinardelrio", "matanzas"];
        } else if (option.toUpperCase() === "INDONESIA") {
          newsSources = ["jakarta", "bandung", "surabaya", "bali"];
        } else if (option.toUpperCase() === "BRAZIL") {
          newsSources = ["brasilwire", "braziljournal", "rionews"];
        } else if (option.toUpperCase() === "PH") {
          newsSources = ["phillipines1", "phillipines2", "phillipines3"];
        } else {
          newsSources = ["thehindu"];
        }

        const responses = await Promise.all(
          newsSources.map((source, index) => {
            return axios.get(`${BASE_URL}/news/${source}`).then((response) => {
              return response;
            });
          })
        );
        const combinedData = responses.reduce((acc, response) => {
          return [...acc, ...response.data];
        }, []);

        setData(combinedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCustomOption]);


  const getCardLimit = useCallback(() => {
    switch (selectedOption) {
      case 1:
        return 11;
      case 3:
        return 35;
      case 10:
        return 98;
      default:
        return 0;
    }
  }, [selectedOption]);

  useEffect(() => {
    if (isCountdownActive) {
      const interval = setInterval(() => {
        setScrollIndex((prevIndex) =>
          prevIndex === Math.min((data?.length || 0) - 1, getCardLimit())
            ? 0
            : prevIndex + 1
        );
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [data, selectedOption, isCountdownActive, getCardLimit])


  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft =
        scrollIndex * sliderRef.current.offsetWidth;
    }
  }, [scrollIndex]);
  

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            clearInterval(timer);
            setShowAlert(true);
            setButtonDisabled(false);
            setSelectedOption(null);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins < 10 ? "0" + mins : mins}:${secs < 10 ? "0" + secs : secs}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffSeconds = Math.floor((now - date) / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffMonths / 12);

    if (diffYears > 0)
      return `${diffYears} year${diffYears > 1 ? "s" : ""} ago`;
    if (diffMonths > 0)
      return `${diffMonths} month${diffMonths > 1 ? "s" : ""} ago`;
    if (diffDays > 0) return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    if (diffHours > 0)
      return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
    if (diffMinutes > 0)
      return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
    return `${diffSeconds} second${diffSeconds > 1 ? "s" : ""} ago`;
  };
  
  return (
    <div className="d-news-timer" ref={sliderRef}>
      {loading ? (
        <LoadingSpinner />
      ) :  data && data.length > 0 ? (
        data.slice(0, getCardLimit() + 1).map((item, index) => (
          <div
            key={index}
            className={`d-news-timer-card ${index === scrollIndex ? "active" : ""}`}
            >
            <p className="d-news-timer-paragraph">
              {item.title
                ? item.title.split(".")[0] + "."
                : "No title available"}
            </p>

            <div className="d-news-timer-option-share-btn-cont">
              <div className="d-news-timer-article-img-container">
                <img src={item.image || games} alt={item.title || "News"} />
              </div>
              <div className="d-select">
                <select
                  value={selectedCustomOption}
                  onChange={(e) => setSelectedCustomOption(e.target.value)}
                >
                  <option value="world">World</option>
                  <option value="india">India</option>
                  <option value="america">America</option>
                  <option value="uk">UK</option>
                  <option value="cuba">Cuba</option>
                  <option value="indonesia">Indonesia</option>
                  <option value="brazil">Brazil</option>
                  <option value="ph">Philippines</option>
                </select>
              </div>
              <div className="d-share-buttons">
                <FacebookShareButton
                  url={"https://www.myselpost.com"}
                  quote={
                    "Your news, your way. Swipe, read, discover with MySelpost!"
                  }
                  hashtag="#myselpost"
                >
                  <FacebookIcon size={28} round />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={"https://www.myselpost.com"}
                  quote={
                    "Your news, your way. Swipe, read, discover with MySelpost!"
                  }
                  hashtag="#myselpost"
                >
                  <WhatsappIcon size={28} style={{ marginLeft: "18%" }} round />
                </WhatsappShareButton>
                <TwitterShareButton
                  url={"https://www.myselpost.com"}
                  quote={
                    "Your news, your way. Swipe, read, discover with MySelpost!"
                  }
                  hashtag="#myselpost"
                >
                  <TwitterIcon size={28} style={{ marginLeft: "29%" }} round />
                </TwitterShareButton>
              </div>
            </div>
            <span className="d-timer-card-date">{formatDate(item.date)}</span>
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
      <div className="d-news-timer-btns">
        {isCountdownActive ? (
          <button className="d-stop-btn" onClick={handleStop}>
            STOP
          </button>
        ) : (
          <button className="d-read-in-btn">Read News In</button>
        )}
        {[1, 3, 10].map((option) => (
          <button
            key={option}
            className={`d-duration ${
              selectedOption === option ? "d-selected" : ""
            }`}
            onClick={() => handleOptionSelect(option)}
            disabled={buttonDisabled}
          >
            {selectedOption === option
              ? `${formatTime(countdown)}`
              : `${option} min`}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DesktopNewsTimer;
