import React from "react";
import "./Practice.scss";

const Practice = () => {
  return (
    <div className="practice-button-wrapper">
     
       <h2>Hover</h2>
    </div>
  );
};

export default Practice;
