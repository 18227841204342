import React, { useState } from 'react';
import './FeedbackForm.scss';

const FeedbackForm = () => {
  // State to manage password input and visibility of content
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  // Correct password (you can change this to a more secure method in real applications)
  const correctPassword = 'parvatiji@123$%^&&**#$%$#@!$$%%^^^&'; 

  // List of image file paths inside the public folder
  const images = [
    '/Docs/1.jpg',
    '/Docs/2.jpg',
    '/Docs/3.jpg',
    '/Docs/4.jpg',
    '/Docs/5.jpg',
    '/Docs/6.jpg',
    '/Docs/7.jpg',
    '/Docs/8.jpg',
    '/Docs/9.jpg',
    '/Docs/10.jpg'
  ];

  // List of PDF file paths inside the public folder
  const pdfs = [
    '/Docs/Driver.pdf',
    '/Docs/GST.pdf'
  ];

  // Function to handle download
  const handleDownload = (filePath, fileName) => {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = fileName;
    link.click();
  };

  // Handle password submission
  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password!');
    }
  };

  return (
    <div>
      {!isAuthenticated ? (
        <div>
          <h2>Enter Password to Access Content</h2>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder="Enter password" 
          />
          <button onClick={handlePasswordSubmit}>Submit</button>
        </div>
      ) : (
        <div>
          <h2>Download Images</h2>
          <div>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Image ${index + 1}`} width="10" />
                <br />
                <button onClick={() => handleDownload(image, `image${index + 1}.jpg`)}>
                  Download Image {index + 1}
                </button>
              </div>
            ))}
          </div>

          <h2>Download PDFs</h2>
          <div>
            {pdfs.map((pdf, index) => (
              <div key={index}>
                <button onClick={() => handleDownload(pdf, `document${index + 1}.pdf`)}>
                  Download PDF {index + 1}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackForm;
